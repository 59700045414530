<template>
	<div class="content">
		<MainSlider :slider="slider"></MainSlider>
		<InfoBlocks :items="infoBlocks"></InfoBlocks>
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<FeedBack></FeedBack>
	</div>
</template>

<script>
	import reviewsData from "@/assets/json/reviews.json";
	import featuresData from "@/assets/json/features.json";
	import projectsData from "@/assets/json/projects.json";

	export default {
		metaInfo: {
			title: 'Артрокетс - веб-студия из Казани',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Веб-студия Артрокетс: профессиональная разработка сайтов, техническая поддержка и поисковое продвижение. Индивидуальный подход к каждому клиенту, бессрочная гарантия на выполненные работы. Оставьте заявку на сайте, и мы свяжемся с вами!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Артрокетс - веб-студия из Казани' },
				{ vmid: 'og:description', property: 'og:description', content: 'Веб-студия Артрокетс: профессиональная разработка сайтов, техническая поддержка и поисковое продвижение. Индивидуальный подход к каждому клиенту, бессрочная гарантия на выполненные работы. Оставьте заявку на сайте, и мы свяжемся с вами!' },
			],
		},
		data: () => ({
			slider: [
				{
					title: 'Разрабатываем сложные сайты и интернет-магазины',
					desc: 'Для лидеров отраслей рынка и тех, кто хочет ими стать',
					linkTitle: 'Подробнее',
					linkHref: '/uslugi/razrabotka/',
					image: require(`@/assets/img/banner/1.png`),
					animatedImageClass: 'neonLogo',
					class: 'whiteText',
					background: '#192029'
				},{
					title: 'Создаем сайты на<br> 1С-Битрикс',
					desc: '1С-Битрикс — система управления сайтом №1 в России <a target="_blank" rel="nofollow" href="https://ratingruneta.ru/cms/">согласно рейтингу Рунета</a> <br><br>С 2017 года сохраняем статус <br><a target="_blank" rel="nofollow" href="https://www.1c-bitrix.ru/partners/570583.php">Золотого сертифицированного партнера</a>',
					linkTitle: 'Подробнее',
					linkHref: '/uslugi/bitrix/',
					image: require(`@/assets/img/banner/2.png`),
					animatedImageClass: 'neonLogo2',
					class: 'whiteText',
					background: '#192029'
				}
			],
			infoBlocks: [
				{
					title: 'Разработка',
					text: 'Создаем сайты, увеличивающие продажи и узнаваемость вашего бизнеса.',
					link: '/uslugi/razrabotka/'
				},{
					title: 'Поисковое продвижение',
					text: 'Увеличиваем трафик из поисковых систем. Повышаем конверсию проекта.',
					//link: '/uslugi/seo/'
				},{
					title: 'Техническая поддержка',
					text: 'Дорабатываем и сопровождаем проекты в режиме 24 на 7.',
					link: '/uslugi/support/'
				},{
					title: 'Битрикс 24',
					text: 'Внедряем и модернизируем корпоративный портал.'
				}
			],
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Проекты',
					quantity: 10,
					quantityTable: 3,
					quantityMobile: 2,
					data: projectsData
				},{
					component: 'advantages',
					name: 'Что вы получаете обратившись<br> в Артрокетс',
					columns: 3,
					sliderInMobile: true,
					data: [
						{
							title: 'Индивидуальный подход',
							text: 'Выделяем персонального менеджера и до 5 специалистов на каждый проект. Под задачи бизнеса, без шаблонных решений.'
						},{
							title: 'Маркетинговый анализ',
							text: 'Изучаем потребности и боли целевой аудитории, анализируем сильные и слабые стороны конкурентов. Ваш сайт будет лучше других решений на рынке.'
						},{
							title: 'Фиксированную стоимость',
							text: 'До начала работ проводим детальную оценку и закрепляем цену услуг в договоре.  Не меняем смету в процессе работы.'
						},{
							title: 'Запуск проекта в срок',
							text: 'Устанавливаем этапы, выполнения работ в договоре и прописываем двустороннюю ответственность за соблюдение сроков.'
						},{
							title: 'Надежного партнера',
							text: 'Дорожим каждым заказчиком и трепетно относимся к своей репутации. Свяжитесь с нами, мы предоставим контакты наших заказчиков для подтверждения данного статуса.'
						},{
							title: 'Бессрочную гарантию',
							text: 'Мы уверены в качестве выполнения всех услуг, поэтому даем неограниченный срок гарантии на работы.'
						}
					]
				},{
					component: 'features',
					name: 'Особенности создания сайтов в Артрокетс',
					data: featuresData
				},{
					component: 'aboutUs',
					name: 'О нас',
					data: [
						{
							component: 'aboutUsInNumbers',
							additionalClass: 'margin4',
							data: [
								{
									title: '50+',
									text: 'уникальных проектов разработано «под ключ»'
								},{
									title: '20+',
									text: 'проектов на регулярной поддержке и SEO'
								},{
									title: '12',
									text: 'профильных специалистов в штате'
								},{
									title: '4',
									text: 'года стабильной работы в digital'
								}
							]
						},{
							component: 'image',
							image: require(`@/assets/img/command3.jpg`),
							additionalClass: 'margin8'
						},{
							component: 'text',
							text: `Основная компетенция нашей веб-студии: создание и дальнейшее развитие корпоративных сайтов, интернет-магазинов и информационных порталов. С нами работают: федеральные страховые компании, региональные банки, порталы недвижимости, медицинские центры, онлайн-магазины косметики, сложного технического оборудования и <router-link to="/portfolio/" class="redLink">многие другие</router-link>.`,
							additionalClass: 'margin2 lh28'
						},{
							component: 'advantages',
							columns: 3,
							sliderInMobile: true,
							data: [
								{
									title: 'UX/UI дизайн',
									text: 'Ставим себя на место пользователя, поэтому проектируем удобные интерфейсы и находим красивые решения для сайта.'
								},{
									title: 'CMS Bitrix',
									text: 'Работаем на базе надежной CMS. Являемся золотым партнером и гордимся этим.'
								},{
									title: 'Mobile First',
									text: 'Проектируем сначала с мобильной версии и плавно двигаемся к большим разрешениям.'
								},{
									title: 'Git',
									text: 'Подключаем систему контроля версий для ускорения разработки проекта.'
								},{
									title: 'SCRUM',
									text: 'Делим процесс на спринты, с задачами  и дедлайнами. Придерживаемся этапов и не срываем сроки.'
								},{
									title: 'Digital-маркетинг',
									text: 'Разрабатываем проекты с прицелом на продажи и продвижение бизнеса в интернете.'
								}
							]
						}
					]
				},{
					component: 'reviews',
					name: 'Отзывы',
					data: reviewsData
				}
			]
		}),
		name: 'home',
		components: {
			MainSlider: () => import('@/components/app/MainSlider'),
			InfoBlocks: () => import('@/components/app/InfoBlocks'),
			WithLeftMenu: () => import('@/components/app/WithLeftMenu'),
			FeedBack: () => import('@/components/app/forms/FeedBack')
		}
	}
</script>
